import React, { useState } from "react"
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown"

import * as S from "./styledComponents"





function AccordionItem({props,i}) {

const [open,setOpen] =useState(false)

const handleClick = () =>{
    setOpen(!open)
}

return(
    <S.AccordionItem>
    <S.Button onClick={handleClick} > <S.Title open={open} dangerouslySetInnerHTML={{__html: props.Title}}/> <S.IconWrapper open = {open}><FiChevronDown/></S.IconWrapper></S.Button>
       
        <S.Body open={open} dangerouslySetInnerHTML={{__html:props.Body}}/>
    </S.AccordionItem>
    )
}




export default function Accordion ({input}) {

    return(
        <S.Container>

            <S.Header> FAQ </S.Header>

           {input.map((item,i)=>{
               return(
                <AccordionItem props= {item} key={i}/>
               )
           })
           }
        </S.Container>
        )
    }
    