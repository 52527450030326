import styled from "styled-components"

export const Container = styled.div`

  margin: 2rem 1rem;
  text-align:center;
    display:flex;

    flex-direction:column;
    align-self:center;


@media (min-width: 1200px){
    width: 1150px;


}

`


export const Button = styled.button`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;

  background-color:transparent;
  border:none;
`
export const AccordionItem = styled.div`
  margin: 0.5rem 0;

  text-align:left;

  @media(min-width:1024px){
    margin: 0.5rem 0;
  }
`

export const Title = styled.h3`
margin:0;
text-align:left;


`

export const Body = styled.p`
  display: ${props => (props.open ? "block" : "none")};
  margin:0;
  padding: 1rem 1rem 0.5rem;
  transition: all 1s linear;
  text-align:left;
`



export const IconWrapper = styled.div`
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  color: var(--secondary60);
  transition: all 0.3s linear;
  /*transition-delay: ${props => (props.open ? "inherit" : "0.6s")};*/
  transform: ${props => (props.open ? "rotate(180deg)" : "inherit")};

  @media(min-width:1024px){
    font-size: 4rem;

  }
`

export const Header = styled.h2``