import styled from "styled-components"

export const Cta = styled.button`
  margin-bottom: 0.5rem;
  background-color: var(--primary-low);
  padding: 12px 24px;
  border-style: none;
  color: black;
  font-size: 1rem;
  border-radius: 32px;
  font-weight:400;

  &:hover {
    background-color: var(--primary-medium);
   
    border-color: var(--primary-high);
  }

  &:active {
    background-color: var(--primary-60);
    border-color: var(--primary-60);
  }

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`

export const SecondaryCta = styled(Cta)`
  background-color: var(--secondary-high);
  color: white;
  margin: 1rem 0;

  &:hover {
    background-color: var(--secondary-medium);
    border-color: var(--secondary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--secondary-high);
    border-color: var(--secondary-high);
  }
`

export const CTAwide = styled.button`
  margin-bottom: 0.5rem;
  background-color: var(--primary-low);
  
  padding: 12px 32px;
  border-style: none;
  border-radius: 24px;
  color: ${props => props.buttonColor || "black"};
  font-size: 1.5rem;
  margin: $(props => props.Margin || "0")

  &:hover {

    background-color: var(--primary-medium);
    border-color: var(--primary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--primary-high);
    border-color: var(--primary-high);
  }
`
export const OutlinedButton = styled.button`

  margin: 1rem 0;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.buttonColor || "var(--dark-grey)"};
  color: ${props => props.buttonColor || "black"};

  padding: 12px 24px;
  border-radius: 8px;
  font-size:1rem;
  &:hover {
    background-color: var(--primary-60);
    border-color: var(--primary-60);
  }

  @media (min-width: 768px) {
    margin-bottom: 0.5rem;
  margin-top: 2rem;
  font-size:1.25rem;
  }
`


export const ContentWrapper = styled.div`
  max-width: var(--large-container-size);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
`


export const SectionHeader = styled.h2`
padding: 0 2rem;
text-align:center;

`


export const QuickFormCta = styled(Cta)`
  background-color: var(--secondary-medium);
  color: white;
  margin: 1rem 0;
  font-size: 1rem;

  &:hover {
    background-color: var(--secondary-medium);
    border-color: var(--secondary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--secondary-high);
    border-color: var(--secondary-high);
  }
  &:disabled {
    background-color: grey;
    border-color: var(--secondary-high);
  }
`

export const QuickFormCta2 = styled(Cta)`
  background-color: white;
  border-style: solid;
  border-color: var(--secondary-high);
  color: var(--secondary-high);
  margin: 1rem 0;
  font-size: 1rem;

  &:hover {
    background-color: var(--secondary-medium);
    border-color: var(--secondary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--secondary-high);
    border-color: var(--secondary-high);
  }
  &:disabled {
    background-color: grey;
    border-color: var(--secondary-high);
  }
`