import React from "react"
import * as S from "./styledComponents"
import * as GS from "../../SharedStyledComponents"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import urlSelect from "../../../utils/helpfunctions"

export default function Component({txt, country, language}) {

  let url = urlSelect(country, language); // returns country-language, e.g. /ch-de/
  let urlCM = url + "/contactme"
  let urlCO;

  if (country==="CH"){
    urlCO = url + "/checkout/?country=CH"
  } else{
    urlCO = url + "/checkout/?country=DE"
  }

  return (
    <S.OuterDiv>
      <S.ConditionDiv>
        <S.Header dangerouslySetInnerHTML={{ __html: txt.Header }} />
        <S.BenefitDiv>
          <S.BenefitWrapper>
            <S.Icon>
              <StaticImage
                src="../../../icon/haken_vorteile_256x256.png"
                alt="Icon"
              />
            </S.Icon>
            <S.Benefit>{txt.Benefit1}</S.Benefit>
          </S.BenefitWrapper>
          <S.BenefitWrapper>
            <S.Icon>
              <StaticImage
                src="../../../icon/haken_vorteile_256x256.png"
                alt="Icon"
              />
            </S.Icon>
            <S.Benefit>{txt.Benefit2}</S.Benefit>
          </S.BenefitWrapper>
          <S.BenefitWrapper>
            <S.Icon>
              <StaticImage
                src="../../../icon/haken_vorteile_256x256.png"
                alt="Icon"
              />
            </S.Icon>
            <S.Benefit>{txt.Benefit3}</S.Benefit>
          </S.BenefitWrapper>

          
        </S.BenefitDiv>
        <Link to={urlCO}>
        <GS.Cta id="TestPricing">{txt.BtnTxt}</GS.Cta>
        </Link>
        <br/>
        <br/>
        <h3> {txt.Unsure}</h3>
        <Link to={urlCM }>
        <GS.Cta id="ConsultPricing">{txt.AskConsult}</GS.Cta>
        </Link>


      </S.ConditionDiv>
      <S.ExplanationDiv>
        <S.Card>
          <S.CardTitle dangerouslySetInnerHTML={{ __html: txt.Exp1.Title }} />
          <S.CardBody>
            <div dangerouslySetInnerHTML={{ __html: txt.Exp1.Body }} />
            <ul>
              <li dangerouslySetInnerHTML={{ __html: txt.Exp1.LI1 }} />
              <li dangerouslySetInnerHTML={{ __html: txt.Exp1.LI2 }} />
            </ul>
          </S.CardBody>
        </S.Card>
        <S.Card>
          <S.CardTitle dangerouslySetInnerHTML={{ __html: txt.Exp2.Title }} />
          <S.CardBody dangerouslySetInnerHTML={{ __html: txt.Exp2.Body }} />
        </S.Card>
        <S.Card>
          <S.CardTitle dangerouslySetInnerHTML={{ __html: txt.Exp3.Title }} />
          <S.CardBody dangerouslySetInnerHTML={{ __html: txt.Exp3.Body }} />
        </S.Card>
      </S.ExplanationDiv>
    </S.OuterDiv>
  )
}
