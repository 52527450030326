const urlSelect = (country, language) => {
  let url;
  if (country === "CH") {
    if (language === "de-CH") {
      url = "/ch-de"
    }
    else if (language === "fr-CH") {
      url = "/ch-fr" // prix?
    }
    else {
      console.error("Unexpected path in urlSelect: " + country + " " + language)
    }
  }
  else if (country === "DE") {
      url = "/de-de"
  }
  else {
    url = ""
    console.error("Unexpected path in urlSelect: " + country + " " + language)
  }
  return url;
}

export default urlSelect