import styled from "styled-components"



export const OuterDiv = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction:column;
  align-items: center;
  
`

export const ConditionDiv = styled.div`

display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding: 2rem 0;

@media (min-width: 1280px) {
  min-height: 50vh;

  } 




`

export const Header = styled.h1`
  text-align:center;
  margin-top: 3rem;

`

export const BenefitDiv = styled.div`
display:flex;
flex-direction: column;
margin: 1rem 0 5rem;


@media (min-width: 768px) {
  flex-direction: row;

  } 
`

export const BenefitWrapper = styled.div`
display:flex;
flex-direction:row;
align-items: center;

margin: 0.25rem 0;

`

export const Icon = styled.div`
  width: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;

  margin-right: 1rem;
  max-width: 64px;
  @media (min-width: 768px) {
    margin-right: 0rem;
  }
`

export const Benefit = styled.p`
margin:0.5rem 0.5rem;

@media (min-width: 768px) {
  margin:1rem 1rem;

  } 
`


export const ExplanationDiv = styled.div`
display:flex;
flex-direction:column;
justify-content: center;
margin: 3rem 0 2rem;
width: 100%;
background-color:var(--secondary20);

@media (min-width: 768px) {
  flex-direction: row;
  } 

`

export const Card = styled.div`

max-width: 400px;

display:flex;
flex-direction:column;
margin: 1rem 1rem;



`

export const CardTitle = styled.h3`
text-align:center;

@media (min-width: 768px) {
    min-height: 4rem;
  } 

`

export const CardBody = styled.p`
margin: 0 1rem;
text-align: justify;
font-size: 1rem;

li{
font-size: 1rem;
  
}

`